'use client';
import { useRouter, useSearchParams } from 'next/navigation';
import { SideBar } from './_components/sidebar/sidebar';
import { useLazyGetCreatorsQuery } from '@lib/redux/api/creators-api';
import { useLazyGetUserQuery, useGetUserQuery } from '@lib/redux/api/user-api';
import { useLogout } from '@lib/hooks/use-logout';
import { cookieService } from '@lib/cookie.service';
import { useEffect, useState } from 'react';
import { useToast } from '@ui/components';
import { SocketEventsProvider } from '@cable/socket-provider';

export default function AppLayout({ children }: React.PropsWithChildren<{}>) {
  const [auth, setAuth] = useState(false);
  const [getUser, { isError, isLoading }] = useLazyGetUserQuery();
  const [getCreator, { data: creators, isSuccess: isCreatorsLoaded }] = useLazyGetCreatorsQuery();
  const { data: user, isError: userError, isSuccess: isUserSuccess } = useGetUserQuery();

  const router = useRouter();
  const onLogout = useLogout();
  const toast = useToast();
  const searchParams = useSearchParams();

  const errorMessage = searchParams.get('error');

  useEffect(() => {
    const token = searchParams.get('token');
    const refresh = decodeURIComponent(searchParams.get('refresh'));
    const id = decodeURI(searchParams.get('id'));

    const fetchData = async () => {
      await getCreator();
      await getUser();
    };

    if (errorMessage) {
      toast(
        {
          title: 'Ooops',
          message: errorMessage,
        },
        {
          type: 'error',
        },
      );
      cookieService.clearAuthCookies();
    }

    if (token && refresh && id) {
      cookieService.setAuthCookise({
        accessToken: token,
        refreshToken: refresh,
        userId: id,
      });
      const inClaimProcess = JSON.parse(localStorage.getItem('badgeInfo'));
      if (inClaimProcess) {
        localStorage.removeItem('badgeInfo');
        router.push(`/badge/?creator=${inClaimProcess.creator}&badgeId=${inClaimProcess.badgeId}`);
      } else {
        router.push('/');
      }
    }

    setAuth(!cookieService.getAuthCookies().accessToken ? true : false);
    if (
      !auth &&
      sessionStorage.getItem('messageUrl') &&
      sessionStorage.getItem('hasRunMessageEffect') === 'true'
    ) {
      router.push(sessionStorage.getItem('messageUrl'));
    }
    fetchData();
  }, [searchParams]);

  if (auth) {
    if (errorMessage) {
      setTimeout(() => {
        onLogout();
      }, 3000);
    } else {
      onLogout();
    }
    return null;
  }

  return (
    <SocketEventsProvider userId={user?.id}>
      <div className="flex h-full min-h-screen w-full flex-col lg:flex-row overflow-x-hidden">
        <SideBar
          user={{
            name: user ? user.name : '',
            avatar: user ? user.avatar : '',
            username: user ? user.username : '',
            id: user ? user.id : '',
            isLoading: !isUserSuccess,
          }}
          creators={
            isCreatorsLoaded
              ? creators.map((creator) => ({
                  name: creator.name,
                  badgeAmount: creator.badges,
                  avatar: creator.avatar,
                  id: creator.id,
                  isNotifications: creator.notifications > 0,
                }))
              : []
          }
          totalBadges={
            isCreatorsLoaded ? creators.reduce((acc, { badges }) => (acc += badges), 0) : 0
          }
          onLogout={onLogout}
        />
        <div className="flex flex-grow">{children}</div>
      </div>
    </SocketEventsProvider>
  );
}
